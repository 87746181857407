import React, { FC } from 'react'
import { BackgroundTheme, BannerAd } from 'advertising/BannerAd/BannerAd'
import { getChannelAdTargeting } from 'pages/ChannelPage/Start/getChannelAdTargeting'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'

export interface Props {
  showOnlySmallAdSizes?: boolean
}

export const BannerAdSection: FC<Props> = ({ showOnlySmallAdSizes }) => {
  const channel = useSelector(channelSelector)
  const { parentChannelDomain, partnerDomain, sportCategory, countryCode } =
    getChannelAdTargeting(channel?.data)
  const targetingOptions = {
    partnerDomain: partnerDomain,
    parentChannelDomain: parentChannelDomain,
    sportCategory: sportCategory,
    countryCode: countryCode
  }
  const currentBreakpoint = useCurrentBreakpoint()
  const isMobile = currentBreakpoint <= Breakpoint.sm
  return (
    <BannerAd
      adId={'div-gpt-ad-1721803826377-0'}
      slotPath={'/21792873324/viewer_page_banner'}
      multiSize={
        showOnlySmallAdSizes || isMobile
          ? [
              [320, 100],
              [320, 240],
              [320, 50],
              [320, 320]
            ]
          : [
              [970, 250],
              [970, 90],
              [980, 240],
              [728, 90],
              [980, 90],
              [980, 120]
            ]
      }
      targetingOptions={targetingOptions}
      backgroundTheme={BackgroundTheme.LightGrey}
    />
  )
}

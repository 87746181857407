import React, { useEffect, Dispatch, FC, SetStateAction } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BroadcastView from './BroadcastView/BroadcastView'
import { getMediaObjectParams } from './getVideoPlayerData'
import { isLikedByUser } from '../../api/viewer/getIsLikedByUser'
import { VideoPlayerData, ViewerParameters } from './viewerPage.interfaces'
import { ReportContentDialog } from './ReportContent/ReportContentDialog'
import {
  broadcastInformationModalIsOpenSelector,
  quikEditDialogIsOpenSelector,
  reportContentDialogIsOpenSelector
} from '../../redux-store/userInterface/userInterface.selectors'
import { userInterface } from '../../redux-store/userInterface/userInterface.actions'
import { channelSelector } from '../../redux-store/channel/channel.selectors'
import { BroadcastInformationModal } from './BroadcastInformationModal/BroadcastInformationModal'
import { QuickEditModal } from './QuickEditModal/QuickEditModal'

interface Props {
  isLoading: boolean
  mediaPlayerData: VideoPlayerData | undefined
  isLiked: boolean
  setIsLiked: Dispatch<SetStateAction<boolean>>
  viewAsAdmin: boolean
  setViewAsAdmin: () => void
  viewerParameters: ViewerParameters
  currentUser: any
  company: string | undefined
  ident: string | undefined
  isPaymentModalDefaultOpen: boolean
}

export const ViewerPageComponent: FC<Props> = ({
  isLoading,
  mediaPlayerData,
  isLiked,
  setIsLiked,
  viewAsAdmin,
  setViewAsAdmin,
  viewerParameters,
  currentUser,
  company,
  ident,
  isPaymentModalDefaultOpen
}) => {
  const channel = useSelector(channelSelector)
  const channelName = channel.data?.name
  useEffect(() => {
    const getUserLikes = async (viewerParams: ViewerParameters) => {
      const liked = await isLikedByUser(getMediaObjectParams(viewerParams))
      setIsLiked(liked)
    }
    if (currentUser && viewerParameters) {
      getUserLikes(viewerParameters)
    }
  }, [company, ident, currentUser, viewerParameters, setIsLiked])

  const dispatch = useDispatch()
  const reportContentDialogIsOpen = useSelector(
    reportContentDialogIsOpenSelector
  )
  const broadcastInformationModalIsOpen = useSelector(
    broadcastInformationModalIsOpenSelector
  )
  const quickEditModalIsopen = useSelector(quikEditDialogIsOpenSelector)
  return (
    <>
      <BroadcastView
        channelName={channelName}
        isLoading={isLoading}
        mediaPlayerData={mediaPlayerData}
        isLiked={isLiked}
        setIsLiked={setIsLiked}
        viewAsAdmin={viewAsAdmin}
        setViewAsAdmin={setViewAsAdmin}
        isPaymentModalDefaultOpen={isPaymentModalDefaultOpen}
      />
      {mediaPlayerData && mediaPlayerData.mediaObject && (
        <ReportContentDialog
          mediaIdent={mediaPlayerData.mediaObject.id!}
          gameIdent={mediaPlayerData.mediaObject?.game?.id}
          channelSlug={mediaPlayerData.mediaObject?.company?.subdomain}
          isOpen={reportContentDialogIsOpen}
          onClose={() => dispatch(userInterface.closeReportContentDialog())}
        />
      )}
      {broadcastInformationModalIsOpen && (
        <BroadcastInformationModal
          defaultOpen={broadcastInformationModalIsOpen}
          onClose={() =>
            dispatch(userInterface.closeBroadcastInformationModal())
          }
        />
      )}
      {quickEditModalIsopen && (
        <QuickEditModal
          defaultOpen={quickEditModalIsopen}
          onClose={() => dispatch(userInterface.closeQuickEditContentDialog())}
        />
      )}
    </>
  )
}

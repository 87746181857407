/* eslint-disable @typescript-eslint/indent */
import React, { FC } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isSwedishHandballSelector } from 'redux-store/channel/channel.selectors'

import GameInteractions from 'pages/ViewerPage/GameInteractions'

import { Statistics } from 'pages/ViewerPage/Statistics/Statistics'
import { Lineups } from 'pages/ViewerPage/Lineups/Lineups'
import { streamSelector } from 'redux-store/stream/stream.selectors'

import { AboutStream } from './components/AboutStream/AboutStream'

export const StreamPageRoutes: FC = () => {
  const stream = useSelector(streamSelector)
  const isSwedishHandball = useSelector(isSwedishHandballSelector)
  const mediaObject = stream.data.mediaObject

  const showGameLineups = isSwedishHandball
  const { hasGame, canComment } = stream

  return (
    <>
      <Switch>
        <Route
          exact
          path={[
            '/:company/games/g/:ident/chat',
            '/:company/watch/:ident/chat',
            '/:company/games/g/:ident/comments',
            '/:company/watch/:ident/comments'
          ]}
        >
          {canComment ? (
            <GameInteractions mediaObject={mediaObject} />
          ) : (
            <AboutStream />
          )}
        </Route>
        <Route
          exact
          path={[
            '/:company/games/g/:ident/statistics',
            '/:company/watch/:ident/statistics'
          ]}
        >
          {hasGame && <Statistics />}
        </Route>
        <Route
          exact
          path={[
            '/:company/games/g/:ident/lineup',
            '/:company/watch/:ident/lineup'
          ]}
        >
          {showGameLineups && <Lineups />}
        </Route>
        <Route
          exact
          path={[
            '/:company/games/g/:ident',
            '/:company/watch/:ident',
            '/:company/games/g/:ident/about',
            '/:company/games/g/:ident/payment',
            '/:company/watch/:ident/about',
            '/:company/watch/:ident/payment'
          ]}
        >
          <AboutStream />
        </Route>
      </Switch>
    </>
  )
}

import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { AccessRestrictionType } from 'interfaces'
import { PaymentModal } from 'pages/ViewerPage/PaymentModal/PaymentModal'
import { VideoPlayerData } from 'pages/ViewerPage/viewerPage.interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { streamSelector } from 'redux-store/stream/stream.selectors'
import {
  broadcastInformationModalIsOpenSelector,
  paymentDialogIsOpenSelector,
  quikEditDialogIsOpenSelector,
  reportContentDialogIsOpenSelector,
  shareContentModalIsOpenSelector
} from 'redux-store/userInterface/userInterface.selectors'
import { userInterface } from 'redux-store/userInterface/userInterface.actions'
import { QuickEditModal } from 'pages/ViewerPage/QuickEditModal/QuickEditModal'
import { BroadcastInformationModal } from 'pages/ViewerPage/BroadcastInformationModal/BroadcastInformationModal'
import { ReportContentDialog } from 'pages/ViewerPage/ReportContent/ReportContentDialog'
import ShareModal from 'components/Modals/ShareModal'

export const StreamModalContent: FC = () => {
  const dispatch = useDispatch()
  const channel = useSelector(channelSelector)
  const channelName = channel.data?.name
  const quickEditModalIsopen = useSelector(quikEditDialogIsOpenSelector)
  const paymentIsOpen = useSelector(paymentDialogIsOpenSelector)
  const shareModalIsOpen = useSelector(shareContentModalIsOpenSelector)

  const stream = useSelector(streamSelector)
  const broadcastInformationModalIsOpen = useSelector(
    broadcastInformationModalIsOpenSelector
  )
  const reportContentDialogIsOpen = useSelector(
    reportContentDialogIsOpenSelector
  )

  const mediaPlayerData: VideoPlayerData = stream.data
  const accessRestriction = mediaPlayerData?.mediaObject?.access_restriction
  const paymentModalDescription = [
    AccessRestrictionType.SubscriptionRequired
  ].includes(accessRestriction)
    ? t`Get access to this stream and all other exclusive content from ${channelName} with a subscription.`
    : t`Get full access to this stream from ${channelName}.`

  return (
    <>
      {paymentIsOpen && mediaPlayerData?.streamUrl!.paymentMethods && (
        <PaymentModal
          mediaObjectId={mediaPlayerData?.mediaObject?.id}
          paymentOptions={mediaPlayerData?.streamUrl!.paymentMethods}
          defaultCurrency="SEK"
          channelSlug={mediaPlayerData.mediaObject.company.subdomain}
          title={mediaPlayerData.mediaObject.title}
          description={paymentModalDescription}
          onClose={() => dispatch(userInterface.closePaymentDialog())}
        />
      )}
      {broadcastInformationModalIsOpen && (
        <BroadcastInformationModal
          defaultOpen={broadcastInformationModalIsOpen}
          onClose={() =>
            dispatch(userInterface.closeBroadcastInformationModal())
          }
        />
      )}
      {mediaPlayerData && mediaPlayerData.mediaObject && (
        <ReportContentDialog
          mediaIdent={mediaPlayerData.mediaObject.id!}
          gameIdent={mediaPlayerData.mediaObject?.game?.id}
          channelSlug={mediaPlayerData.mediaObject?.company?.subdomain}
          isOpen={reportContentDialogIsOpen}
          onClose={() => dispatch(userInterface.closeReportContentDialog())}
        />
      )}
      {quickEditModalIsopen && (
        <QuickEditModal
          defaultOpen={quickEditModalIsopen}
          onClose={() => dispatch(userInterface.closeQuickEditContentDialog())}
        />
      )}
      {shareModalIsOpen && (
        <ShareModal
          defaultOpen={shareModalIsOpen}
          mediaId={mediaPlayerData?.mediaObject?.id}
          mediaTitle={
            mediaPlayerData?.mediaObject?.game?.title ||
            mediaPlayerData?.mediaObject?.title
          }
          companySlug={
            mediaPlayerData?.mediaObject?.game?.display_company?.subdomain
          }
          onClose={() => dispatch(userInterface.closeShareContentModal())}
        />
      )}
    </>
  )
}

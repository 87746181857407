import React, { FC } from 'react'
import { StreamOverview } from './components/StreamOverview/StreamOverview'
import { StreamPageRoutes } from './StreamPageRoutes'
import { StreamMessage } from './components/StreamMessage/StreamMessage'
import { StreamSubmenu } from './components/StreamSubmenu/StreamSubmenu'

export const StreamPageLayout: FC = () => {
  return (
    <div>
      <StreamMessage />
      <StreamOverview />
      <StreamSubmenu />
      <StreamPageRoutes />
    </div>
  )
}

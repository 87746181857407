import React, { FC, useState } from 'react'
import styles from './StreamOverview.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  streamSelector,
  streamViewTypeSelector
} from 'redux-store/stream/stream.selectors'
import { getIsDefaultThumbnail } from 'helpers/getIsDefaultThumbnail'
import { TeamVsTeamBackground } from '../Backgrounds/TeamVsTeam/TeamVsTeamBackground'
import { MediaObject } from 'interfaces'
import { StreamInfo } from '../StreamInfo/StreamInfo'
import { TopActionsBar } from '../TopActionsBar/TopActionsBar'
import { CTASection } from '../CTASection/CTASection'
import { RootState } from 'redux-store/store'

import { StreamCountdown } from '../StreamCountdown/StreamCountdown'
import dayjs from 'dayjs'
import { PermissionsState } from 'redux-store/permissions/permissions.interface'
import { permissionsSelector } from 'redux-store/permissions/permissions.reducer'
import { ViewType } from 'redux-store/stream/stream.interface'
import { PlayIconButton } from './PlayIconButton'
import useAdminAccess from 'hooks/AdminAccess'
import { GeoBlockedInfo } from '../GeoBlockedInfo/GeoBlockedInfo'
import { userInterface } from 'redux-store/userInterface/userInterface.actions'
import classNames from 'classnames'

export const StreamOverview: FC = () => {
  const stream = useSelector(streamSelector)
  const dispatch = useDispatch()
  const now = Date.now() / 1000
  const permissions: PermissionsState = useSelector(permissionsSelector)
  const mediaObject: MediaObject = stream.data.mediaObject
  const accessRestriction = mediaObject.access_restriction
  const adminAccess = useAdminAccess()
  const showGeoBlockedView = stream?.data.streamUrl?.geoBlock
  const shouldShowCountdown =
    mediaObject.type !== 'video' &&
    !mediaObject.is_replay &&
    dayjs(mediaObject.live_start_at).diff(now, 'd') <= 5

  const [showCountdown, setShowCountdown] = useState(shouldShowCountdown)

  const isDefaultThumbnail =
    mediaObject.thumbnail && getIsDefaultThumbnail(mediaObject.thumbnail)
  const shouldShowTeamsBackgrouns = isDefaultThumbnail && mediaObject.game
  const roles = useSelector((state: RootState) => state.roles)
  const isViewingAsAdmin = roles.isViewingAsAdmin
  const viewType: ViewType = useSelector(streamViewTypeSelector)

  const userHasAccess = adminAccess.hasChannelAccess
    ? isViewingAsAdmin
    : permissions.canView

  const streamIsUpcoming = mediaObject.live_start_at > now

  const onCountDownIsZero = () => {
    setShowCountdown(false)
  }
  const showPlayButton =
    userHasAccess &&
    [
      ViewType.REPLAY,
      ViewType.HIGHLIGHT,
      ViewType.LIVE,
      ViewType.VIDEO
    ].includes(viewType)

  return (
    <div
      className={classNames(styles.StreamOverview, {
        [styles.HasTeamVsTeamBackgrond]: shouldShowTeamsBackgrouns
      })}
    >
      <div className={styles.Background}>
        <div className={styles.Overlay}>
          <TopActionsBar />
          <div className={styles.InnerContainer}>
            {showCountdown && (
              <div className={styles.CountdownContainer}>
                <StreamCountdown
                  streamStartAt={mediaObject.live_start_at}
                  hasReachedZero={onCountDownIsZero}
                />
              </div>
            )}
            {!showCountdown && showPlayButton && (
              <div className={styles.PlayIconContainer}>
                <PlayIconButton
                  onClick={() => dispatch(userInterface.openViewStream())}
                />
              </div>
            )}
            {showGeoBlockedView ? (
              <GeoBlockedInfo />
            ) : (
              <>
                <StreamInfo />
                <CTASection
                  userHasAccess={userHasAccess}
                  streamIsUpcoming={streamIsUpcoming}
                  streamHasDVR={false}
                  accessRestriction={accessRestriction}
                />
              </>
            )}
          </div>
        </div>
        {shouldShowTeamsBackgrouns ? (
          <div className={styles.TeamVsTeamBackground}>
            <TeamVsTeamBackground />
          </div>
        ) : (
          <img
            className={styles.BackgroundImage}
            src={mediaObject.thumbnail}
            alt={`thumbnail for ${mediaObject.title}`}
            data-testid="thumbnail-background"
          />
        )}
      </div>
    </div>
  )
}

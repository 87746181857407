import React, { FC, useCallback, useEffect, useState } from 'react'
import { getViewerParams } from 'pages/ViewerPage/getViewerParams'
import {
  VideoPlayerData,
  ViewerParameters
} from 'pages/ViewerPage/viewerPage.interfaces'
import { useChannelDataStatus } from 'pages/ChannelPage/useChannelDataStatus'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { useDispatch, useSelector } from 'react-redux'
import useCompanyParam from 'hooks/CompanyParam'
import { useLocation, useParams } from 'react-router-dom'
import { clearStream } from 'redux-store/stream/stream.actions'
import useCurrentUser from 'hooks/CurrentUser'
import { createGetStreamActions } from 'pages/ViewerPage/createGetStreamActions'
import {
  streamIsLoadingSelector,
  streamSelector
} from 'redux-store/stream/stream.selectors'
import { Loader } from 'components/Loader/Loader'
import { StreamPageLayout } from './StreamPageLayout'
import subscriberActions from 'redux-store/NchanSubscriber/subscriber.actions'
import { StreamModalContent } from './StreamModalContent'

import { isViewingStreamSelector } from 'redux-store/userInterface/userInterface.selectors'
import { userInterface } from 'redux-store/userInterface/userInterface.actions'
import { ViewerPageTitle } from 'pages/ViewerPage/ViewerPageTitle/ViewerPageTitle'
import { getViewerPageUrl } from 'pages/ViewerPage/getViewerPageUrl'
import { VideoPageMeta } from './VideoPageMeta'

import { StreamPlayingView } from './StreamPlayingView/StreamPlayingView'
import styles from './StreamPage.module.scss'
import useAdminAccess from 'hooks/AdminAccess'
import classNames from 'classnames'

export const StreamPage: FC = () => {
  const viewParamsCallback = useCallback(getViewerParams, [])
  const location = useLocation()
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const streamIsLoading = useSelector(streamIsLoadingSelector)
  const isViewingStream = useSelector(isViewingStreamSelector)
  const adminAccess = useAdminAccess()

  const stream = useSelector(streamSelector)
  const mediaPlayerData: VideoPlayerData = stream.data
  const company = useCompanyParam()
  const channel = useSelector(channelSelector)

  const mediaObject = stream?.data?.mediaObject
  const { channelDataIsReady } = useChannelDataStatus(channel, company)
  const isAdmin = adminAccess.isAdmin
  const title = mediaObject?.title
  const rootDomain =
    channel?.data?.root_domain || channel?.data?.root_channel?.root_domain
  const viewerPageUrl = getViewerPageUrl(mediaObject, rootDomain)
  const { ident } = useParams<{ ident?: string }>()
  const [parameters, setParameters] = useState<ViewerParameters | undefined>(
    undefined
  )
  const [isGamePath, setIsGamePath] = useState(
    location.pathname.includes('/games/g/')
  )
  const getCurrentUrl = useCallback(() => {
    return window.origin + location.pathname
  }, [ident])
  useEffect(() => {
    dispatch(userInterface.closeViewStream())
  }, [])
  useEffect(() => {
    if (location) {
      setIsGamePath(location.pathname.includes('/games/g/'))
    }
  }, [location])

  useEffect(() => {
    if (channelDataIsReady) {
      setParameters({
        ...viewParamsCallback({
          isGamePath,
          company,
          ident,
          currentUrl: getCurrentUrl()
        }),
        hasAdminAccess: isAdmin
      })
    }
  }, [
    company,
    ident,
    isAdmin,
    viewParamsCallback,
    channelDataIsReady,
    isGamePath,
    isViewingStream,
    getCurrentUrl
  ])

  const getVideoPlayerDataObject = useCallback(
    async (mediaObjectParams: ViewerParameters) => {
      if (!streamIsLoading) {
        await createGetStreamActions(dispatch, mediaObjectParams)
      }
    },
    [dispatch, streamIsLoading]
  )

  useEffect(() => {
    if (parameters) {
      getVideoPlayerDataObject(parameters)
    } else {
      return () => {
        dispatch(clearStream())
      }
    }
  }, [currentUser, parameters, dispatch])

  useEffect(() => {
    if (mediaPlayerData?.mediaObject?.event_source_uri) {
      dispatch(
        subscriberActions.connect(
          mediaPlayerData?.mediaObject?.event_source_uri
        )
      )
    }
    return () => {
      dispatch(subscriberActions.disconnect())
    }
  }, [mediaPlayerData?.mediaObject?.event_source_uri])

  return (
    <div
      className={classNames(styles.StreamPage, {
        [styles.IsViewingStream]: isViewingStream
      })}
    >
      {streamIsLoading && (
        <div className={styles.LoadingState}>
          <Loader />
        </div>
      )}
      {stream.data && mediaObject && (
        <>
          <VideoPageMeta />
          <ViewerPageTitle
            title={title}
            rootDomain={rootDomain}
            viewerPageUrl={viewerPageUrl}
          />
          {isViewingStream ? <StreamPlayingView /> : <StreamPageLayout />}
          <StreamModalContent />
        </>
      )}
    </div>
  )
}

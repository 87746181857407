import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { streamSelector } from 'redux-store/stream/stream.selectors'
import { MediaObject } from 'interfaces'
import { StreamLabel } from 'components/cards/BroadcastThumbnail/ThumbnailLabel/ThumbnailLabel'
import { TeamBadge } from '../Backgrounds/TeamVsTeam/TeamBadge/TeamBadge'
import { StreamSubmenu } from '../StreamSubmenu/StreamSubmenu'

import styles from './StreamNavigation.module.scss'
import { StreamPageRoutes } from 'features/viewerPage/StreamPageRoutes'

export const StreamNavigation: FC = () => {
  const stream = useSelector(streamSelector)
  const mediaObject: MediaObject = stream.data.mediaObject
  const streamTitle = mediaObject.title
  const isLive = mediaObject.is_live
  const isReplay = mediaObject.is_replay
  const publishedAt = mediaObject.published_at
  const startAt = mediaObject.live_start_at
  const highlightFromDate = mediaObject.highlight_source
    ? mediaObject.published_at
    : null
  const streamType = mediaObject.type
  const categories = mediaObject.categories
  const getCategories = () => {
    if (categories && categories.length > 0) {
      return (
        <div className={styles.Categories} data-testid="stream-categories">
          <h2 key={categories[0].translated_name} className={styles.Category}>
            {categories[0].translated_name}
          </h2>
          {categories[1] && (
            <h2 key={categories[1].translated_name} className={styles.Category}>
              | {categories[1].translated_name}
            </h2>
          )}
        </div>
      )
    } else {
      return null
    }
  }
  return (
    <div className={styles.Container}>
      <div className={styles.NavigationContainer}>
        <div className={styles.TopContainer}>
          <div className={styles.Content}>
            <StreamLabel
              isLive={isLive}
              isReplay={isReplay}
              highlightFromDate={highlightFromDate}
              publishedAt={publishedAt}
              startAt={startAt}
              type={streamType}
              externalStyle={styles.Label}
            />

            <h1 data-testid="stream-title" className={styles.Title}>
              {streamTitle}
            </h1>
            {getCategories()}
          </div>
          {mediaObject.game && (
            <div className={styles.TeamLogos}>
              <TeamBadge
                name={mediaObject.game?.home_team?.name}
                logoUrl={mediaObject.game?.home_team?.badge_url}
                externalStyle={styles.Logo}
              />
              <div className={styles.Divider} />
              <TeamBadge
                name={mediaObject.game?.away_team?.name}
                logoUrl={mediaObject.game?.away_team?.badge_url}
                externalStyle={styles.Logo}
              />
            </div>
          )}
        </div>
        <StreamSubmenu />
      </div>
      <div className={styles.PageRoutesWrapper}>
        <StreamPageRoutes />
      </div>
    </div>
  )
}

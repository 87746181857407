import React, { FC, useEffect, useState } from 'react'

import VideoPlayer from 'components/video/VideoPlayer'
import { useSelector } from 'react-redux'
import { streamSelector } from 'redux-store/stream/stream.selectors'
import { StreamNavigation } from '../components/StreamNavigation/StreamNavigation'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import VideoPlayerPreloader from 'pages/ViewerPage/Preloaders/videoPlayerPreloader/VideoPlayerPreloader'
import { TopActionsBar } from '../components/TopActionsBar/TopActionsBar'
import { StreamMessage } from '../components/StreamMessage/StreamMessage'
import classNames from 'classnames'
import styles from './StreamPlayingView.module.scss'
import {
  Breakpoint,
  getWidth,
  useCurrentBreakpoint
} from 'hooks/CurrentBreakpoint'

export const StreamPlayingView: FC = () => {
  const stream = useSelector(streamSelector)
  const channel = useSelector(channelSelector)
  const isRootOrClubChannel = channel?.data?.isRootClubChannel
  const isRootOrChild =
    channel?.data?.isRootChannel || channel?.data?.isChildOfRootChannel
  const currentBreakpoint = useCurrentBreakpoint()
  const isMobile = currentBreakpoint <= Breakpoint.sm
  const screenWidth = getWidth()
  const videoPlayerHeight = screenWidth * 0.5625

  const [videoHeight, setVideoHeight] = useState(videoPlayerHeight)
  useEffect(() => {
    setVideoHeight(screenWidth * 0.5625)
  }, [screenWidth])
  const mediaPlayerData = stream.data
  return (
    <div
      className={classNames(styles.OuterContainer, {
        [styles.IsRootOrChild]: isRootOrChild,
        [styles.IsRootClubChannel]: isRootOrClubChannel
      })}
    >
      <div className={styles.Container}>
        <StreamMessage />
        <div className={styles.InnerContainer}>
          <div className={styles.VideoContainer}>
            <div className={styles.TopActionsBar}>
              <TopActionsBar />
            </div>

            {mediaPlayerData.streamUrl !== null &&
            mediaPlayerData.streamUrl.urls ? (
              <div className={styles.VideoPlayer}>
                <VideoPlayer
                  mediaObject={mediaPlayerData.mediaObject}
                  streamUrls={mediaPlayerData.streamUrl!.urls}
                  autoPlay={false}
                />
              </div>
            ) : (
              <VideoPlayerPreloader />
            )}
          </div>
          <div
            style={{ paddingBottom: isMobile ? videoHeight : 0 }}
            className={styles.Navigation}
          >
            <StreamNavigation />
          </div>
        </div>
      </div>
    </div>
  )
}
